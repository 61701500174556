import React from "react"
import { Seo, Complet } from "../components"
import { CheckIcon } from '@heroicons/react/solid'
import { Disclosure, Transition, Switch } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useState } from 'react'
import axios from "axios";
import Notification from "../components/Notification.js"
import Bouton from "../components/Bouton.js"
import Recaptcha from 'react-recaptcha'
import { PopupButton } from "react-calendly";


const tiers = [
  {
    name: 'Maintenance site vitrine',
    priceMonthly: 49.99,
    description: 'Abonnement réservé aux sites internet vitrine (site qui présente votre entreprise et ses services).',
    includedFeatures: [
      <span>Surveillance du site <strong>24h/24 7j/7</strong></span>,
      <span>Maintenance préventive <strong>(mise à jour du CMS & extensions)</strong></span>,
      <span>Maintenance corrective <strong>(résolution de problèmes techniques, failles de sécurité)</strong></span>,
      <span>Sauvegarde <strong>quotidienne</strong> sur serveur externe</span>,
      <span>Ajout, modification de contenu <strong>(maximum 2 heures/mois)</strong></span>,
      <span>Heure supplémentaire : <strong>30,00€</strong></span>,
      <span>Support en direct (tchat)<sup>(2)</sup></span>,
      <span>Intervention sous <strong>24h00<sup>(3)</sup></strong></span>,
    ],
    delay: 100,
  },
  {
    name: 'Maintenance site e-commerce',
    priceMonthly: 69.99,
    description: 'Abonnement réservé aux sites internet e-commerce (boutique en ligne)',
    includedFeatures: [
      <span>Surveillance du site <strong>24h/24 7j/7</strong></span>,
      <span>Maintenance préventive <strong>(mise à jour du CMS & extensions)</strong></span>,
      <span>Maintenance corrective <strong>(résolution de problèmes techniques, failles de sécurité)</strong></span>,
      <span>Sauvegarde <strong>quotidienne</strong> sur serveur externe</span>,
      <span>Ajout, modification de contenu & page produit / catégorie <strong>(maximum 2 heures/mois)</strong></span>,
      <span>Heure supplémentaire : <strong>30,00€</strong></span>,
      <span>Support en direct (tchat)<sup>(2)</sup></span>,
      <span>Intervention sous <strong>24h00<sup>(3)</sup></strong></span>,
    ],
    delay: 200,
  },
]

const faqs = [
  {
    question: "🤷‍♀️ Qu'est-ce-que la maintenance d'un site Internet ?",
    answer:
      "La maintenance d'un site Internet consiste à mener un ensemble d'interventions assurant le bon fonctionnement de votre site Internet sur la durée. Il s'agit donc d'appliquer des mises à jour du contenu, des technologies du site, de la sécurité... ",
  },
  {
    question: "🤔 Pourquoi la maintenance d'un site Internet est indispensable ?",
    answer:
      "La maintenance d'un site Internet permet de le maintenir à jour et de le garder toujours plus sain, sécurisé et performant. Il est impossible de garantir la sécurité et la performance de votre site Internet sans maintenance.",
  },
]

const features = [
  { name: 'Maintenance préventive', description: 'Mise à jour de votre site internet et de ses extensions (WordPress, Prestashop...)', delay: 0 },
  { name: 'Maintenance corrective', description: 'Nous résolvons les bogues et autres dysfonctionnements qui surviennent sur votre site internet.', delay: 200 },
  {
    name: 'Modification et ajout de contenu',
    description: 'Faites publier vos articles et pages produits par notre agence web.',
    delay: 100,
  },
  { name: 'Sauvegarde quotidienne', description: 'Chaque jour, une sauvegarde de votre site est effectuée et stockée sur un serveur externe.', delay: 300 },
  { name: 'Surveillance', description: 'Nous surveillons votre site 24h/27 7j/7 et intervenons sous 24h00 en cas de bug détecté ou signalé.', delay: 400 },
  { name: 'Assistance', description: 'Notre assistance technique est disponible par mail, par téléphone ou par le tchat en direct.', delay: 500 },
]

const MaintenanceSiteInternet = () => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  const [agreed, setAgreed] = useState(false)

  const [list, setList] = useState([]);

  const [token, setToken] = useState();

  const [serverState, setServerState] = useState({
    submitting: "hidden",
    submittingPulse: "",
    status: null
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: "hidden",
      submittingPulse: "",
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const validate = (form) => {
    let valid = true;
    let erreurs = [];
    if (!token) {
      erreurs.push("Veuillez valider le captcha");
      valid = false;
    }
    if (!form.nom.value.trim()) {
      erreurs.push("Merci de renseigner votre nom.");
      valid = false;
    }
    var nom = form.nom.value.trim();
    if (nom.length < 2) {
      erreurs.push("Votre nom doit contenir 2 caractères au minimum.");
      valid = false;
    }
    if (!form.prenom.value.trim()) {
      erreurs.push("Merci de renseigner votre prénom.");
      valid = false;
    }
    var prenom = form.prenom.value.trim();
    if (prenom.length < 2) {
      erreurs.push("Votre prénom doit contenir 2 caractères au minimum.");
      valid = false;
    }
    if (!form.email.value.trim()) {
      erreurs.push("Merci de renseigner votre email.");
      valid = false;
    }
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!form.email.value.match(regex)) {
      erreurs.push("Merci de renseigner un email correct.");
      valid = false;
    }
    var regexPhone = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
    if (!form.numero.value.match(regexPhone)) {
      erreurs.push("Merci de renseigner un numéro de téléphone correct.");
      valid = false;
    }
    if (!form.message.value.trim()) {
      erreurs.push("Merci de renseigner votre message.");
      valid = false;
    }
    var message = form.message.value.trim();
    if (message.length < 25) {
      erreurs.push("Merci de renseigner un message de 25 caractères minimum.");
      valid = false;
    }
    if (!agreed) {
      erreurs.push("Vous devez accepter notre Politique de Confidientialité.");
      valid = false;
    }
    return {
      valid,
      erreurs
    }
  };


  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    let { valid, erreurs } = validate(form);
    if (valid) {
      setServerState({ submitting: "block", submittingPulse: "animate-pulse", });
      axios({
        method: "post",
        url: "https://www.koomet.fr/contact",
        data: {
          nom: form.nom.value,
          prenom: form.prenom.value,
          email: form.email.value,
          numero: form.numero.value,
          message: form.message.value,
          entreprise: form.entreprise.value,
          recaptcha: token,
        }
      })
        .then(r => {
          handleServerResponse(true, "Merci!", form);
          showToast("success");
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    } else {
      showToast("danger", erreurs);
    }
  };

  const showToast = (type, erreurs) => {
    let toastProperties = null;
    const id = Math.floor((Math.random() * 100) + 1);
    switch (type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Message envoyé',
          description: 'Votre message a bien été envoyé !',
          icon: 'check',
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Erreur',
          icon: 'error',
          description: erreurs.map((erreur, i) => {
            return <span key={i} className="whitespace-pre-wrap">{erreur + "\n"}</span>

          }),
        }
        break;
      default:
        setList([]);
    }
    setList([...list, toastProperties]);
  }

  return (
    <Complet>
          <Seo 
      
      title="Maintenance de site internet | à partir de 49,99€/mois - Koomet" 
      desc="Confiez la maintenance de votre site internet à notre agence web professionnelle ! Modifications, mises à jour de votre site internet vitrine / e-commerce."
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Maintenance de site Internet"
      bread2url="/maintenance-site-internet/"
      
      />
      <div>
        <div className="relative">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-indigo-100 dark:text-violet/20" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
        </div>
        <div className="relative">
          <div className="inset-x-0 bottom-0 h-1/2" />
            <div className="relative shadow-xl sm:overflow-hidden dark:shadow-md">
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-t bg-w-primary mix-blend-multiply" />
              </div>
              <div className="relative text-center px-5 pt-5 pb-10 max-w-5xl mx-auto">
                <h1 data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="text-5xl font-extrabold tracking-tight text-white">Maintenance de site Internet</h1>
                <p data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="mt-3 text-md font-bold text-white uppercase">à partir de 49,99€ / mois</p>
                <p data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease-in-out-cubic" className="mt-6 text-lg text-white">
                  Vous avez déjà un site internet mais vous ne savez pas comment le mettre à jour, comment ajouter du contenu ? Confiez la maintenance de votre site internet à notre agence web !
                </p>

              </div>
            </div>
            <Notification toastList={list} />
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
              <div className="mx-auto text-center">
                <p className="text-4xl font-extrabold text-gray-700 dark:text-gray-200">Nos offres de maintenance de site internet en détails</p>
              </div>
              <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
                {features.map((feature) => (
                  <div data-sal="zoom-in" data-sal-duration="1000" data-sal-delay={feature.delay} data-sal-easing="ease-in-out" key={feature.name} className="relative text-center p-5 shadow-md dark:bg-d-secondary dark:outline-violet dark:outline-2 dark:outline rounded-lg">
                    <dt>
                      <CheckIcon className="inline-block h-6 w-6 text-green-500" aria-hidden="true" />
                      <p className="mt-4 text-lg leading-6 font-bold text-gray-700 dark:text-gray-300">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 text-base dark:text-gray-400">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          <div className="max-w-7xl mx-auto py-16 pb-0 px-4 md:py-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center">
              <h2 data-sal="flip-up" data-sal-duration="500" data-sal-easing="ease-in-out" className="text-2xl md:text-4xl font-extrabold text-gray-700 dark:text-gray-200 text-center">Combien coûte la maintenance d'un site Internet ?</h2>
              <p data-sal="flip-up" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease-in-out" className="mt-8 text-xl text-gray-500 dark:text-gray-300 text-center">
                Notre solution clé en main vous permet de <strong>déléguer la maintenance de votre site Internet à 100%</strong>.
              </p>
              <p data-sal="flip-up" data-sal-duration="500" data-sal-delay="400" data-sal-easing="ease-in-out" className="mt-3 text-xl text-gray-500 dark:text-gray-300 text-center">
                Notre agence web propose la <strong>mise à jour</strong>, <strong>la sauvegarde</strong>, <strong>la surveillance</strong>, <strong>la réparation</strong> de votre site Internet.
              </p>
            </div>
            <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2 ">
              {tiers.map((tier) => (
                <div data-sal="zoom-in" data-sal-duration="1000" data-sal-delay={tier.delay} data-sal-easing="ease-in-out" key={tier.name} className=" outline-gray-700 dark:outline-violet dark:outline-2 hover:outline-2 outline outline-1 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-600 dark:bg-d-secondary/30">
                  <div className="p-6">
                    <p className="text-lg leading-6 font-bold text-violet dark:text-gray-200">{tier.name}</p>
                    <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">{tier.description}</p>
                    <p className="mt-8">
                      <span className="text-4xl font-extrabold dark:text-gray-200">{tier.priceMonthly}€</span>{' '}
                      <span className="text-base font-medium dark:text-gray-400">/mois<sup>(1)</sup></span>
                    </p>
                    <div className="mt-8">
                      <span className="block w-full text-center font-semibdold text-sm">Cet abonnement vous intéresse ?</span>
                      <AniLink
                        cover bg="#6377E9" direction="up" duration={0.6} to="/contact/"
                        className="mt-2 block w-full bg-gray-800 dark:bg-w-primary dark:hover:bg-w-primary/90 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-w-primary transition ease-in-out duration-200"
                      >
                        Contactez-nous !
                      </AniLink>
                    </div>
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <p className="text-xs font-medium text-gray-700 dark:text-gray-400 tracking-wide uppercase">Cet abonnement comprend</p>
                    <ul className="mt-6 space-y-4">
                      {tier.includedFeatures.map((feature) => (
                        <li key={feature} className="flex space-x-3">
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-400" aria-hidden="true" />
                          <span className="text-sm text-gray-500 dark:text-gray-300">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4 text-sm">
              <p>(1) : Abonnement mensuel avec engagement de 12 mois minimum.</p>
              <p>(2) : Le lien du support en direct vous sera communiqué et le support sera accessible depuis l'administration de votre site Internet.</p>
              <p>(3) : Les interventions sont aussi effectuées les week-ends et jours fériés.</p>
            </div>
            <div className="mt-4 sm:mt-12">
              <div className="relative">
                <div className="max-w-lg mx-auto rounded-lg shadow-lg shadow-indigo-50 dark:shadow-md overflow-hidden lg:max-w-none lg:flex dark:outline dark:outline-2 dark:outline-violet">
                  <div className="flex-1 bg-white dark:bg-d-secondary/30 px-6 py-8 lg:p-12">
                    <h3 className="text-2xl font-extrabold text-gray-700 dark:text-gray-200 sm:text-3xl">Maintenance ponctuelle</h3>
                    <p className="mt-6 text-base text-gray-500 dark:text-gray-300">
                      L'agence web Koomet est à votre disposition pour vos besoins ponctuels de maintenance : changement d'hébergeur, migration d'un site internet, résolutions de problèmes techniques, mise en conformité RGPD, etc.
                    </p>
                  </div>
                  <div className="py-8 px-6 text-center bg-gray-50 dark:bg-w-primary lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                    <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-700 dark:text-gray-200">
                      <span>45,00€</span>
                      <span className="ml-3 text-xl font-medium text-gray-500 dark:text-gray-300">/ heure</span>
                    </div>
                    <div className="mt-6">
                      <div className="rounded-md shadow">
                        <AniLink
                          cover bg="#6377E9" direction="up" duration={0.6} to="/contact/"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                        >
                          Contactez-nous !
                        </AniLink>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto py-12 mt-6">
              <div className="mx-auto divide-y-2 divide-gray-200 dark:divide-violetBright/50">
                <h4 className="text-center font-bold text-gray-700 dark:text-gray-200 sm:text-3xl">Questions fréquentes sur l'entretien et la maintenance d'un site internet</h4>
                <dl className="mt-6 max-w-5xl mx-auto">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt className="text-lg">
                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-violet bg-indigo-100 p-5 rounded-md">
                              <span className="font-medium text-violet">{faq.question}</span>
                              <span className="ml-6 h-7 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform transition duration-100 ease-in-out')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Transition
                            show={open}
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Disclosure.Panel static as="dd" className="mt-4 pr-12">
                              <p className="text-base text-gray-500 dark:text-gray-300">{faq.answer}</p>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto overflow-hidden">
            <div className="relative bg-w-secondary dark:bg-d-secondary/95 rounded-large p-10 mx-auto">
              <div className="text-center">
                <h2 className="font-bold tracking-tight dark:text-white text-gris text-3xl">Contacter notre agence web pour la maintenance de votre site internet</h2>
              </div>
              <div className="mt-12 max-w-2xl mx-auto">
                <form onSubmit={handleOnSubmit} className={serverState.submittingPulse + " grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"}>
                  <div>
                    <label htmlFor="nom" className="block text-sm font-medium text-gris dark:text-white">
                      Nom*
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="text"
                        name="nom"
                        id="nom"
                        autoComplete="given-name"
                        className="py-3 px-4 block w-full dark:bg-d-secondary focus:outline-none shadow-sm dark:border-violet focus:ring-violet focus:border-violet border border-gris rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="prenom" className="block text-sm font-medium text-gris dark:text-white">
                      Prénom*
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        type="text"
                        name="prenom"
                        id="prenom"
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full dark:bg-d-secondary shadow-sm focus:outline-none dark:border-violet focus:ring-violet focus:border-violet border-gris border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="entreprise" className="block text-sm font-medium text-gris dark:text-white">
                      Entreprise
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="entreprise"
                        id="entreprise"
                        autoComplete="organization"
                        className="py-3 px-4 block w-full dark:bg-d-secondary shadow-sm focus:outline-none dark:border-violet focus:ring-violet focus:border-violet border-gris border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-gris dark:text-white required">
                      Adresse email*
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="elon@tesla.com"
                        className="py-3 px-4 block w-full dark:bg-d-secondary shadow-sm focus:outline-none dark:border-violet focus:ring-violet focus:border-violet border border-gris rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="numero" className="block text-sm font-medium text-gris dark:text-white">
                      Numéro de téléphone
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name="numero"
                        id="numero"
                        autoComplete="tel"
                        className="py-3 px-4 block w-full dark:bg-d-secondary focus:outline-none dark:border-violet focus:border-violet focus:ring-violet border border-gris rounded-md"
                        placeholder="06 18 96 16 80"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="message" className="block text-sm font-medium text-gris dark:text-white">
                      Message*
                    </label>
                    <div className="mt-1">
                      <textarea
                        required
                        id="message"
                        name="message"
                        rows={4}
                        className="py-3 px-4 block w-full dark:bg-d-secondary focus:outline-none shadow-sm dark:border-violet focus:ring-violet focus:border-violet border border-gris rounded-md"
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <Switch
                          checked={agreed}
                          onChange={setAgreed}
                          className={classNames(
                            agreed ? 'bg-w-primary' : 'bg-gray-300',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                          )}
                        >
                          <span className="sr-only">Accepter notre Politique de Confidentialité</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              agreed ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-w-secondary shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </div>
                      <div className="ml-3">
                        <p className="text-base text-gris dark:text-white">
                          En sélectionnant ceci, vous acceptez notre{' '}
                          <AniLink className="font-medium text-gris dark:text-white underline" cover bg="#6377E9" direction="up" duration={0.6} to="/politique-de-confidentialite/">
                            Politique de Confidentialité
                          </AniLink>
                          .
                        </p>
                      </div>
                    </div>
                    <Recaptcha verifyCallback={token => { setToken(token) }} required hl="fr_FR" className="mt-3 w-full inline-flex items-center justify-center lg:items-start lg:justify-start" sitekey="6LdKWtUdAAAAAOgIZAfQsOpsmekLheMe_NxqKELP" render="explicit" />
                  </div>
                  <div className="sm:col-span-2">
                    <Bouton modele="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-w-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gris">

                      <svg className={serverState.submitting + " animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Envoyer le message
                    </Bouton>
                  </div>
                </form>
              </div>
            </div>
          </div>


      <div className="relative bg-gradient-to-r from-indigo-400 via-violet to-indigo-400">
        <div className="mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Vous souhaitez en discuter de vive voix ?</span>
          </h2>
          <p className="mt-4 text-2xl font-medium leading-6 text-indigo-100">
            Programmons un rendez-vous !
          </p>

          <PopupButton
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-violet bg-white hover:bg-indigo-50 sm:w-auto transition duration-200 hover:-translate-y-0.5 hover:shadow-md"
                        pageSettings={{
                          backgroundColor: 'ffffff',
                          hideEventTypeDetails: false,
                          hideGdprBanner: false,
                          hideLandingPageDetails: false,
                          primaryColor: '6377E9',
                          textColor: '6377E9'
                        }}
                        styles={{}}
                        text="Prendre rendez-vous 🤗"
                        url="https://calendly.com/koomet"
                      />
        </div>
      </div>
    </Complet>
  )
}

export default MaintenanceSiteInternet
